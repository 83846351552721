<template>
  <div id="camera-action">
    <div class="camera-buttons">
      <div class="button-wrapper">
        <div class="camera-button" ref="captureButton" v-on:click="capture">
          <div class="camera-button-image">
            <span class="fal fa-camera"></span>
          </div>
          <div class="camera-button-text">キャプチャー</div>
        </div>
      </div>
      <div class="button-wrapper" ref="uploadButton" @click="checking">
        <div class="camera-button">
          <div class="camera-button-image">
            <span class="far fa-folder"></span>
          </div>
          <div class="camera-button-text">参照</div>
        </div>
      </div>
      <input
        type="file"
        id="upload1"
        hidden
        @change="uploadFile1"
        ref="uploadFile"
        accept="image/*"
      />
      <div class="button-wrapper" style="margin-top: 20px">
        <div class="camera-button-1">
          <div style="width: 70%">
            <div style="display: flex">
              <div class="camera-button-image" ref="movieCameraButton">
                <span class="fal fa-camera-movie"></span>
              </div>
              <div style="padding-top: 10px">
                <div class="camera-button-text">録画</div>
              </div>
            </div>
          </div>
          <div style="display: flex">
            <div
              class="camera-button camera-button-small"
              v-on:click="startRecordVideo"
              ref="startRecord"
            >
              <div class="camera-button-image">
                <span class="fas fa-play"></span>
              </div>
            </div>
            <div
              class="camera-button camera-button-small"
              v-on:click="stopRecordVideo"
              ref="stopRecord"
            >
              <div class="camera-button-image">
                <span class="fas fa-stop"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="button-wrapper">
        <div class="camera-button-1">
          <div style="width: 70%">
            <div style="display: flex">
              <div class="camera-button-image" ref="microphoneButton">
                <span class="fal fa-microphone-alt"></span>
              </div>
              <div style="padding-top: 10px">
                <div class="camera-button-text">録音</div>
              </div>
            </div>
          </div>
          <div style="display: flex">
            <div
              class="camera-button camera-button-small"
              v-on:click="startRecordSpeech"
              ref="startRecordSpeech"
            >
              <div class="camera-button-image">
                <span class="fas fa-play"></span>
              </div>
            </div>
            <div
              class="camera-button camera-button-small"
              v-on:click="stopRecordSpeech"
              ref="stopRecordSpeech"
            >
              <div class="camera-button-image">
                <span class="fas fa-stop"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="button-wrapper">
        <div class="camera-button" v-on:click="listRecord">
          <div class="camera-button-image">
            <span class="fal fa-list"></span>
          </div>
          <div class="camera-button-text">ファイル一覧</div>
        </div>
      </div>
    </div>
    <VueModal v-model="showModal" title="ファイル名" wrapperClass="recordPopup">
      <label for="fname">ファイル名</label>
      <div>
        <label for="fname" ref="textError" style="display: none;"
          >テキスト名は空です</label
        >
      </div>
      <input type="text" id="fname" name="fname" v-model="namerecord" />
      <div style="text-align: center">
        <button class="button" ref="buttonSetName" @click="setNameRecord()">
          設定
        </button>
      </div>
    </VueModal>
    <VueModal
      v-model="showModalList"
      title="ファイル一覧"
      wrapperClass="recordPopup"
    >
      <BeeGridTable :loading="flagLoading" :columns="columns" :data="data">
        <template slot="hop">操作</template>
        <template slot-scope="{ row, index }" slot="op">
          <Button
            type="primary"
            size="small"
            style="margin-right: 5px"
            @click="handleDetail(row, index)"
            >詳細</Button
          >
          <Button type="error" size="small" @click="handleDelete(row, index)"
            >削除</Button
          >
        </template>
      </BeeGridTable>
    </VueModal>
  </div>
</template>

<script>
import VueModal from "@kouts/vue-modal";
import moment from "moment";
import axios from "axios";

axios.defaults.headers.post["Content-Type"] = "application/json";
export default {
  name: "CameraAction",
  props: ["subscribers"],
  components: {
    VueModal,
    moment,
  },
  data() {
    return {
      selectedStreamId: 0,
      nameStream: "",
      OPENVIDU_SERVER_URL: process.env.VUE_APP_OPENVIDU_SERVER_URL,
      showModal: false,
      selectRecord: 0,
      namerecord: "",
      recordObject: {},
      showModalList: false,
      flagRecord: false,
      timeJoin: 0,
      flagLoading:true,
      columns: [
        // {
        //   title: "No",
        //   type: "index",
        //   hideFilter: true,
        //   slot: "no",
        //   width: 50,
        // },
        // {
        //   title: "No",
        //   key: "id",
        //   align: "center",
        //   resizable: true,
        //   sortable: true,
        // },
        {
          title: "日付",
          key: "datetime",
          align: "center",
          type: "date", //date field
          dateType: "datetime", //date、datetime、year、month
          format: "yyyy-MM-dd hh:mm:ss A", //yyyy-MM-dd 、 hh:mm、HH:mm:ss ...
          sortable: true,
          sortType: "desc",
        },
        {
          title: "ファイル名",
          key: "name",
          align: "center",
          resizable: true,
          sortable: true,
        },
        {
          title: "タイプ",
          key: "type",
          align: "center",
          resizable: true,
        },
        {
          title: "",
          key: "op",
          slot: "op",
          headSlot: "hop",
        },
      ],
      data: [],
      dataOperator: [],
      preCam: null,
    };
  },
  mounted() {
    if (this.selectedStreamId == 0) {
      setTimeout(() => {
        this.timeJoin = this.timeJoin + 1;
      }, 1000);
    }
    this.disableButton();
    this.$root.$on("change-camera", (sub) => {
      if (sub != undefined) {
        if (
          !JSON.parse(sub.stream.connection.data).clientData.includes(
            "Operator"
          )
        ) {
          this.selectedStreamId = sub.stream.connection.connectionId;
          this.$refs.startRecord.style.cssText =
            "pointer-events: visible;opacity: 1;";
          this.$refs.startRecordSpeech.style.cssText =
            "pointer-events: visible;opacity: 1;";
          this.$refs.captureButton.style.cssText = 
           "pointer-events: visible;opacity: 1;";
          this.$refs.uploadButton.style.cssText = 
           "pointer-events: visible;opacity: 1;";
          this.$refs.movieCameraButton.style.cssText =
           "pointer-events: visible;opacity: 1;";
          this.$refs.microphoneButton.style.cssText =
           "pointer-events: visible;opacity: 1;";
          this.nameStream = JSON.parse(sub.stream.connection.data).clientData;
          this.stopRecordVideo();
        }
        if(sub.stream.session.connection.data.includes('"data":true')){
            if(!JSON.parse(sub.stream.connection.data).clientData.includes('Device')){
            this.disableButton();
            this.$refs.captureButton.style.cssText = 
            "pointer-events: none;opacity: 0.6;";
            this.$refs.uploadButton.style.cssText = 
            "pointer-events: none;opacity: 0.6;";
          }
        }else{
          if(!JSON.parse(sub.stream.connection.data).clientData.includes('Device')){
            this.disableButton();
            this.$refs.captureButton.style.cssText = 
            "pointer-events: none;opacity: 0.6;";
            this.$refs.uploadButton.style.cssText = 
            "pointer-events: none;opacity: 0.6;";
          }else{
            this.disableButton();
          }
        }
        // if(!JSON.parse(sub.stream.connection.data).clientData.includes('Device')){
        //   this.disableButton();
        //   this.$refs.captureButton.style.cssText = 
        //    "pointer-events: none;opacity: 0.6;";
        //   this.$refs.uploadButton.style.cssText = 
        //    "pointer-events: none;opacity: 0.6;";
        // }
      }
    });
    this.$root.$on("device-left", (selected) => {
      if (selected == this.selectedStreamId) {
        this.selectedStreamId = 0;
        this.$root.$emit("change-camera", undefined);
        this.$root.$emit("stop-record-video");
        this.disableButton();
      }
    });
    this.$root.$on("startRecord", (selected) => {
      this.flagRecord = false;
      this.$refs.startRecord.style.cssText =
        "pointer-events: visible;opacity: 1;";
      this.$refs.stopRecord.style.cssText =
        "pointer-events: none;opacity: 0.6;";
      this.$refs.startRecordSpeech.style.cssText =
        "pointer-events: visible;opacity: 1;";
      this.$refs.stopRecordSpeech.style.cssText =
        "pointer-events: none;opacity: 0.6;";
    });
    this.$root.$on("stopRecord", (selected) => {
      this.showModal = false;
      this.flagRecord = true;
      this.$refs.stopRecord.style.cssText =
        "pointer-events: visible;opacity: 1;";
      this.$refs.startRecord.style.cssText =
        "pointer-events: none;opacity: 0.6;";
      this.$refs.startRecordSpeech.style.cssText =
        "pointer-events: none;opacity: 0.6;";
      this.$refs.stopRecordSpeech.style.cssText =
        "pointer-events: none;opacity: 0.6;";
    });
    this.$root.$on("stopRecordSpeech", () => {
      this.showModal = false;
      this.$refs.stopRecordSpeech.style.cssText =
        "pointer-events: visible;opacity: 1;";
      this.$refs.startRecord.style.cssText =
        "pointer-events: none;opacity: 0.6;";
      this.$refs.startRecordSpeech.style.cssText =
        "pointer-events: none;opacity: 0.6;";
      this.$refs.stopRecord.style.cssText =
        "pointer-events: none;opacity: 0.6;";
    });
    this.$root.$on(
      "recordObjectList",
      (recordObject, recordOperator, operator) => {
        this.flagLoading = true;
        this.data = [];
        this.dataOperator = [];
        var myMap = new Map();
        console.log(recordObject)
        console.log(recordOperator)
        console.log(operator)
        for (var i = 0; i < recordObject.length; i++) {
          if (recordObject[i].sessionId === operator) {
            this.dataOperator.push(recordObject[i]);
            myMap.set(recordObject[i].createdAt, recordObject[i]);
          }
        }
        var object = {};
        var temp;
        for (var i = 0; i < recordOperator.length; i++) {
          // if(recordOperator[i].hasImage){
          //     object = {
          //       id: i + 1,
          //       name: recordOperator[i].name,
          //       type: "画像",
          //       recordurl: recordOperator[i].url,
          //       recordid: recordOperator[i].url.replace("/opt/openvidu/recordings/",""),
          //       datetime: recordOperator[i].time,
          //     };
          //     this.data.push(object);
          // }else{
          //   temp = myMap.get(recordOperator[i].time);
          //   if(temp != undefined){
          //     object = {
          //       id: i + 1,
          //       name: recordOperator[i].name,
          //       type: temp.hasVideo == true ? "録画" : "録音",
          //       recordurl: recordOperator[i].url,
          //       recordid: temp.id,
          //       datetime: temp.createdAt,
          //     };
          //   }
          //   this.data.push(object);
          // }

          temp = myMap.get(recordOperator[i].time);
          if (recordOperator[i].hasImage) {
            object = {
              id: i + 1,
              name: recordOperator[i].name,
              type: "画像",
              recordurl: recordOperator[i].url,
              recordid: recordOperator[i].url.split('/')[4],
              datetime: recordOperator[i].time,
            };
            this.data.push(object);
          } else {
            // if (temp != undefined) {
              object = {
                id: i + 1,
                name: recordOperator[i].name,
                type: recordOperator[i].hasVideo == true ? "録画" : "録音",
                recordurl: recordOperator[i].url,
                recordid: recordOperator[i].url.split('/')[4],
                datetime: recordOperator[i].time,
              };
              this.data.push(object);
            // }
          }
        }
        this.sortbyDate();
        let count = 1,
          flag = 1,
          noTimCall = true;
        this.showModalList = true;
        setTimeout(() => {  
        this.flagLoading = false;}, 500);
        // setInterval(() => {
        //   if (flag === 1 && noTimCall) {
        //     this.showModalList = false;
        //     setTimeout(() => {
        //       flag = 0;
        //       count++;
        //       this.showModalList = true;
        //     }, 500);
        //   }
        // }, 500);
        // this.showModalList = true;
      }
    );
    this.$root.$on("successdeleteRecord", (row) => {
      const i = this.data.findIndex((p) => p.id == row.id);
      if (i >= 0) {
        this.data.splice(i, 1);
      }
    });
  },

  methods: {
    savePreCam() {
      this.preCam = this.$refs.selectCamera.value;
    },
    updateMainVideoStreamManager(event) {
      var seletedConnectionId = event.target.value;
      var sub = this.subscribers.find(
        (sub) => sub.stream.connection.connectionId == seletedConnectionId
      );
      this.$root.$emit("change-camera", sub);
      this.$refs.startRecord.style.cssText =
        "pointer-events: visible;opacity: 1;";
      this.$refs.startRecordSpeech.style.cssText =
        "pointer-events: visible;opacity: 1;";
    },
    getConnectionData(sub) {
      if (
        !JSON.parse(sub.stream.connection.data).clientData.includes("Operator")
      ) {
        const { connection } = sub.stream;
        return JSON.parse(connection.data).clientData;
      }
    },
    capture() {
      this.$root.$emit("bigcamera-capture");
    },
    uploadFile1(e) {
      var imageTemp = e
      if (this.$refs.uploadFile.files[0].type.includes("image/")) {
         this.$root.$emit("upload-file", imageTemp);
      } else {
        this.$alert("画像の拡張子ではないですので、ご確認ください！");
      }
    },
    checking() {
      if (this.selectedStreamId == 0) {
        this.$root.$emit("bigcamera-unload");
      } else {
        this.$refs.uploadFile.value = "";
        this.$refs.uploadFile.click();
      }
    },
    listRecord() {
      this.$root.$emit("listRecord");
    },
    startRecordVideo() {
      this.selectRecord = 0;
      this.showModal = true;
    },
    stopRecordVideo() {
      this.$root.$emit("stop-record-video");
    },
    startRecordSpeech() {
      this.selectRecord = 1;
      this.showModal = true;
    },
    stopRecordSpeech() {
      this.$root.$emit("stop-record-speech", this.selectedStreamId);
    },
    actionGetLink(key_name) {
      axios
        .post(`${this.OPENVIDU_SERVER_URL}/vti/generatelinks3`, {
          key_name,
        })
        .then((response) => {
          if (response.status === 200) {
            if (response.data != "") {
              window.open(response.data, "_blank");
            }
          }
        })

        .catch((error) => console.log(error));
    },
    handleDetail(row, index) {
      if (navigator.userAgent.toLowerCase().includes("mac os")) {
        if (this.subscribers.length == 0) {
          this.actionGetLink(row.recordurl);
          // window.open(row.recordurl, "_blank");
        } else {
          this.$alert(
            "Safariブラウザ上でビデオ通話しながらファイルをご覧になると、ビデオ通話の性能に影響されますので、ご理解ください！"
          );
        }
      } else {
        // window.open(row.recordurl, "_blank");
        this.actionGetLink(row.recordurl);
      }
    },
    handleDelete(row, index) {
      this.$confirm("このファイルを削除しますか？").then(() => {
        this.$root.$emit("removeRecord", row);
      });
    },
    disableButton() {
      this.$refs.startRecord.style.cssText =
        "pointer-events: none;opacity: 0.6;";
      this.$refs.stopRecord.style.cssText =
        "pointer-events: none;opacity: 0.6;";
      this.$refs.startRecordSpeech.style.cssText =
        "pointer-events: none;opacity: 0.6;";
      this.$refs.stopRecordSpeech.style.cssText =
        "pointer-events: none;opacity: 0.6;";
      this.$refs.movieCameraButton.style.cssText =
        "pointer-events: none;opacity: 0.6;";
      this.$refs.microphoneButton.style.cssText =
        "pointer-events: none;opacity: 0.6;";
    },
    sortbyDate() {
      function compare(a, b) {
        if (a.datetime > b.datetime) return -1;
        if (a.datetime < b.datetime) return 1;
        return 0;
      }

      return this.data.sort(compare);
    },
    setNameRecord() {
      if (this.namerecord.trim() != "") {
        var date = "";
        try {
          date = moment().format("L").replaceAll("/", "");
        } catch (error) {
          var datetime = moment().format("L").split("/");
          for (var i = 0; i < datetime.length; i++) {
            date = date + datetime[i];
          }
        }
        var time1 = moment().format("LTS").split(":");
        var hour = time1[0].length > 1 ? time1[0] : "0" + time1[0];
        var minute = time1[1].length > 1 ? time1[1] : "0" + time1[1];
        var second = time1[2].length > 3 ? time1[2] : "0" + time1[2];
        var time = hour + minute + second.replace(" ", "");
        var name = date + time;
        this.$refs.buttonSetName.style.cssText =
          "pointer-events: none;opacity: 0.6;";
        if (this.selectRecord == 0) {
          this.$root.$emit(
            "start-record-video",
            name,
            this.nameStream,
            this.namerecord
          );
          // this.$root.$emit("switch-camera",this.selectedStreamId);
        } else {
          this.$root.$emit(
            "start-record-speech",
            name,
            this.nameStream,
            this.namerecord
          );
        }
        this.$refs.textError.style.cssText = "display: none;";
      } else {
        this.$refs.textError.style.cssText = "color:red; display: inline;";
      }
    },
  },
  beforeDestroy() {
    this.$root.$off('change-camera');
    this.$root.$off('device-left');
    this.$root.$off('startRecord');
    this.$root.$off('stopRecord');
    this.$root.$off('stopRecordSpeech');
    this.$root.$off('recordObjectList');
    this.$root.$off('successdeleteRecord');
  }
};
</script>

<style lang="scss">
@import "./src/assets/scss/abstracts/main";
.recordPopup .vm-content {
  overflow: hidden;
}
#fname {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 1px #ee7e05 solid;
}
.button {
  background-color: #ee7e05;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}
.basic-slide:focus,
.basic-slide:active {
  color: #377d6a;
  text-indent: 0;
  background: #fff;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  &::-webkit-input-placeholder {
    color: #aaa;
  }
  + label {
    transform: translateX(-100%);
  }
}
// .camera-buttons {
//   margin-top: 20px;
// }

.camera-select {
  position: relative;
  &:before {
    content: "";
    width: 45px;
    height: 45px;
    position: absolute;
    top: 0;
    right: 0;
    background-color: darken(map-get($color, white), 8%);
    z-index: 1;
  }
  &:after {
    content: "";
    position: absolute;
    top: 19px;
    right: 17px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 8px solid darken(map-get($color, white), 40%);
    z-index: 2;
  }
  select {
    width: 100%;
    appearance: none;
    height: 45px;
    padding: 5px 10px;
  }
}
.button-wrapper {
  display: flex;
  + .button-wrapper {
    margin-top: 20px;
  }
}
.camera-button-small {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
  margin-left: 10px;
}
.camera-button-1 {
  padding: 8px;
  border-radius: 7px;
  background-color: darken(map-get($color, white), 8%);
  border: 1px solid darken(map-get($color, white), 15%);
  display: flex;
  align-items: center;
  &:not(.camera-button-small) {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .camera-button-image {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: map-get($color, main);
    span {
      color: map-get($color, white);
      font-size: 2rem;
    }
  }
}
.camera-button {
  padding: 8px;
  border-radius: 7px;
  background-color: darken(map-get($color, white), 8%);
  border: 1px solid darken(map-get($color, white), 15%);
  display: flex;
  cursor: pointer;
  align-items: center;
  &:not(.camera-button-small) {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .camera-button-image {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: map-get($color, main);
    span {
      color: map-get($color, white);
      font-size: 2rem;
    }
  }
}
.camera-button-text {
  padding-left: 10px;
}
</style>