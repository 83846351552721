<template>
  <div id="big-image">
    <p class="block-title">WEARABLE画像</p>
    <div class="big-image-wrapper">
      <video
        id="device-camera"
        ref="device"
        class="img"
        autoplay
        poster="../../assets/icon/main-qimg-ae44ee18db5078485b0955f941260386.png"
      />
      <img
        src="../../assets/icon/main-qimg-ae44ee18db5078485b0955f941260386.png"
        ref="imgdevice"
        class="img"
        style="display: none"
      />
    </div>
    <div class="thumbnail-image-wrapper" :style="[subscribers.length ==0 ? {'visibility': 'hidden'} : {'visibility': 'visible'}]"> 
      <div class="row">
      <div class="col-2" 
          v-for="(sub) in subscribers"
          :value="sub.stream.connection.connectionId"
          :key="sub.stream.connection.connectionId"
          :class="[JSON.parse(sub.stream.connection.data).clientData.includes('Device') ? 'fa fa-glasses ' : 'fa fa-user']"
          :style="[JSON.parse(sub.stream.connection.data).clientData.includes('Device') ? {'color': 'transparent'} : '']"
          >
      <div class="thumbnail-item" :class="{ active: sub.stream.connection.connectionId === subscriberchoice.stream.connection.connectionId}"
            >
      <user-video autoplay loop 
            :stream-manager="sub"
            @click.native="actionStreamVideo(sub)"
      />
        </div>
        </div>
      </div>
    </div>
    <VueModal
      v-model="showModal"
      title="画像編集"
      v-on:after-open="capture"
      style="overflow: scroll"
    >
    <VueModal v-model="showModal2" title="画像保存" wrapperClass="recordPopup">
      <label for="fname">ファイル名</label>
      <div>
        <label for="fname" ref="textError" style="display: none;"
          >テキスト名は空です</label
        >
      </div>
      <input type="text" id="fname" name="fname" v-model="namerecord" />
      <div style="text-align: center">
        <button class="button" ref="buttonSetName" @click="setNameRecord()">
          設定
        </button>
      </div>
    </VueModal>
      <div class="col-sm-6 col-md-8 col-lg-12" style="display: flex">
        <div class="editor-container">
          <div class="editor">
            <div class="tool" v-on:click="deleteAll">
              <img src="../../assets/icon/trash.png" class="imageStyle" />
            </div>
            <div class="tool">
              <img
                src="../../assets/icon/undo.png"
                v-on:click="undo"
                class="imageStyle"
              />
            </div>
            <div class="tool">
              <img
                src="../../assets/icon/redo.png"
                v-on:click="redo"
                class="imageStyle"
              />
            </div>
            <div class="tool" v-on:click="freedrawing">
              <img src="../../assets/icon/drawfree.png" class="imageStyle" />
            </div>
            <div class="tool" v-on:click="text">
              <img src="../../assets/icon/text.png" class="imageStyle" />
            </div>
            <div class="tool" v-on:click="circle">
              <img src="../../assets/icon/circle.png" class="imageStyle" />
            </div>
            <div class="tool" v-on:click="rectangle">
              <img src="../../assets/icon/rectangle.png" class="imageStyle" />
            </div>
            <div style="float: left; margin-top: 5px">太さ</div>
            <div class="wrapper" style="margin-top: 8px">
              <custom-slider
                :values="sliderValues"
                raising
                v-model="slider"
                v-on:change="setSizeSlide()"
              />
            </div>
          </div>
          <Editor
            :canvasWidth="854"
            :canvasHeight="480"
            ref="editor"
            editorId="1"
          />
          <div class="container" style="margin-top:10px;">
            <div class="row">
              <div class="col">
                <div class="button-wrapper">
                  <input
                    type="file"
                    id="upload"
                    hidden
                    accept="image/*"
                    ref="uploadFileInner"
                    @change="uploadFileInner"
                  />
                  <label for="upload" class="w-100" style="margin: 0;color:black;">
                    <div class="camera-button">
                      <div class="camera-button-image">
                        <span class="far fa-folder"></span>
                      </div>
                      <div class="camera-button-text">参照</div>
                    </div>
                  </label>
                </div>
              </div>
              <div class="col">
                <div class="button-wrapper" v-on:click="transferFile">
                  <label class="w-100" style="margin: 0;color:black;">
                    <div class="camera-button">
                      <div class="camera-button-image">
                        <span class="fal fa-upload"></span>
                      </div>
                      <div class="camera-button-text">画像転送</div>
                    </div>
                  </label>
                </div>
              </div>
              <div class="col">
                <div class="button-wrapper" v-on:click="saveImage">
                  <label class="w-100" style="margin: 0;color:black;">
                    <div class="camera-button">
                      <div class="camera-button-image">
                        <span class="far fa-cloud-upload-alt"></span>
                      </div>
                      <div class="camera-button-text">画像保存</div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="color-table">
          <div class="color-container red" v-on:click="setColorRed"></div>
          <div class="color-container yellow" v-on:click="setColorYellow"></div>
          <div class="color-container purple" v-on:click="setColorPurple"></div>
          <div class="color-container green" v-on:click="setColorGreen"></div>
          <div
            class="color-container lightblue"
            v-on:click="setColorLightblue"
          ></div>
          <div class="color-container pink" v-on:click="setColorPink"></div>
          <div class="color-container blue" v-on:click="setColorBlue"></div>
          <div class="color-container black" v-on:click="setColorBlack"></div>
        </div>
        <canvas
          ref="canvas1"
          id="canvas1"
          width="854px"
          height="480px"
          style="display:none"
        ></canvas>
        <Editor
            :canvasWidth="854"
            :canvasHeight="480"
            ref="canvas"
            editorId="2"
            style="display:none"
          />
      </div>
    </VueModal>
  </div>
</template>

<script>
import UserVideo from "./UserVideo";
import VueModal from "@kouts/vue-modal";
import Editor from "vue-image-markup";
import CustomSlider from "vue-custom-range-slider";
import { fabric } from "fabric";
import moment from "moment";

export default {
  name: "BigImage",
  props: {
    subscriber: Object,
    subscribers: Array,
  },
  components: {
    UserVideo,
    VueModal,
    Editor,
    CustomSlider,
  },
  data() {
    return {
      namerecord : "",
      canvas: null,
      flagCapture: false,
      uploadFileEvent: Object,
      uploadFlg: false,
      sub: this.subscriber,
      subscriberchoice: Object,
      showModal: false,
      showModal2: false,
      videoWidth: 0,
      videoHeight: 0,
      activeItem: null,
      id: "", // text id
      mode: "",
      witdh: "", // if rectangle width and height is not 0,editor disable editing rectangle and add the rectangles with fixed properties
      height: "", //	if rectangle width and height is not 0,editor disable editing rectangle and add the rectangles with fixed properties
      canvasTemp: null,
      widthCanvas: 0,
      heightCanvas: 0,
      fill: "",
      fontFamily: "",
      fontSize: "",
      placeholder: "",
      stroke: "",
      strokeWidth: "", // Rectangle border width
      slider: 7,
      sliderValues: [
        {
          label: "",
          value: 3,
        },
        {
          label: "",
          value: 5,
        },
        {
          label: "",
          value: 7,
        },
        {
          label: "",
          value: 9,
        },
        {
          label: "",
          value: 15,
        },
      ],
    };
  },
  mounted() {
    this.canvasTemp = new fabric.Canvas("canvas");
    this.canvasTemp.setDimensions({ width: 1080, height: 610 });
    const rect = new fabric.Rect({
      fill: "red",
      width: 20,
      height: 20,
    });
    this.canvasTemp.add(rect);
    this.$root.$on("change-camera", (sub) => {
      this.sub = sub;
      if (this.sub != undefined) {
        if (
          JSON.parse(sub.stream.connection.data).clientData.includes("Operator")
        ) {
          this.$refs.device.style.cssText =
            "border-color: red;border-style: solid;border-width: 3px;display:none;";
          this.$refs.imgdevice.style.cssText =
            "border-color: red;border-style: solid;border-width: 3px;display:inline;";
          this.flagCapture = false;
        } else {
          this.flagCapture = true;
          this.sub.addVideoElement(this.$refs.device);
          this.$refs.device.style.cssText =
            "border-color: green;border-style: solid;border-width: 3px;display:inline;";
          this.$refs.imgdevice.style.cssText = "display:none;";
          // for(var i =0;i<= this.subscribers;i++){
          //   if(Object.is(this.subscribers[i], this.sub)){
          //     this.activeItem = i;
          //   }
          // }
          console.log(this.sub)
          this.subscriberchoice = sub;
        }
      } else {
        this.$refs.device.style.cssText =
          "border-color: red;border-style: solid;border-width: 3px;display:none;";
        this.$refs.imgdevice.style.cssText =
          "border-color: red;border-style: solid;border-width: 3px;display:inline;";
        this.flagCapture = false;
      }
    });

    this.$root.$on("bigcamera-capture", (sub) => {
      if (this.flagCapture) {
        this.showModal = true;
      } else {
        this.$alert("キャプチャができません。");
        this.showModal = false;
      }
    });
    this.$root.$on("bigcamera-unload", (sub) => {
      this.$alert("接続されているデバイスがありません。");
    });
    this.$root.$on("close-popup", (sub) => {
      this.showModal = false;
    });
    this.$root.$on("upload-file", (e) => {
      if (e.target.files.length != 0) {
        this.uploadFileEvent = e;
        this.uploadFlg = true;
        this.showModal = true;
      }
    });
    if (this.sub == undefined) {
      this.$refs.device.style.cssText =
        "border-color: red;border-style: solid;border-width: 3px;";
    }
    // setTimeout(function () {
    //       for (var i = 0, len = that.$refs.deviceStreamPopup.length; i < len; i++) {
    //         if (that.$refs.deviceStreamPopup[i].id === stream.id) {
    //           that.$refs.deviceStreamPopup[i].srcObject = stream;
    //           break;
    //         }
    //       }
    //     }, 500);
  },
  methods: {
    selectItem(i) {
      this.activeItem = i;
    },
    actionStreamVideo(event){
    console.log(event);
    this.$root.$emit("change-camera", event);
    },
    streamVideo(event){
    console.log(event);
    event.addVideoElement(this.$refs.device);
    },
    capture() {
      if (this.flagCapture && !this.uploadFlg) {
        this.$refs.canvas1
          .getContext("2d")
          .drawImage(this.$refs.device, 0, 0, 854, 480);
        this.$refs.canvas.setBackgroundImage(this.$refs.canvas1.toDataURL());
        this.$refs.editor.setBackgroundImage(this.$refs.canvas1.toDataURL());
      } else {
        this.uploadFile(this.uploadFileEvent);
        this.showModal = true;
        this.uploadFlg = false;
      }

      this.strokeWidth = this.slider.toString();
      this.$refs.editor.set("selectMode");
    },
    setSizeSlide() {
      this.strokeWidth = this.slider.toString();
      this.setMode();
    },
    deleteAll() {
      this.$refs.editor.clear();
      console.log(this.$refs.canvas.canvas.toDataURL());
      this.$refs.editor.setBackgroundImage(this.$refs.canvas.canvas.toDataURL());
    },
    undo() {
      this.$refs.editor.undo();
    },
    redo() {
      this.$refs.editor.redo();
    },
    freedrawing() {
      this.mode = "freeDrawing";
      let customizeFreeDrawing = {
        stroke: this.stroke,
        strokeWidth: this.slider,
      };
      this.$refs.editor.set(this.mode, customizeFreeDrawing);
      // this.$refs.editor.set('freeDrawing');
    },
    text() {
      let textModeOptions = {
        fill: this.stroke,
        fontFamily: "Verdana",
        fontSize: 16,
        placeholder: "Type something",
        fontWeight: "normal",
        fontStyle: "normal",
      };
      this.mode = "text";
      this.$refs.editor.set("text", textModeOptions);
    },
    circle() {
      this.mode = "circle";
      let circleModeParams = { stroke: this.stroke ,strokeWidth: this.slider,strokeUniform: false};
      this.$refs.editor.set("circle", circleModeParams);
    },
    rectangle() {
      this.mode = "rect";
      let customizeRectangle = { stroke: this.stroke ,strokeWidth: this.slider,strokeUniform:false};
      this.$refs.editor.set(this.mode, customizeRectangle);
    },
    uploadFile(e) {
      this.$refs.editor.clear();
      this.$refs.editor.uploadImage(e);
      this.$refs.canvas.uploadImage(e);
      // var ctx = this.$refs.canvas.getContext("2d");
      // // var ctx1 = this.$refs.editor.canvas.getContext("2d");
      // ctx.clearRect(0, 0, this.$refs.canvas.width, this.$refs.canvas.height);
      // var img = new Image();
      // img.src = URL.createObjectURL(e.target.files[0]);
      // img.onload = function () {
      //   // ctx.width = img.width;
      //   // ctx.height = img.height;
      //   ctx.drawImage(img, 0, 0, 853, 480);
      //   // ctx1.drawImage(img, 0, 0, 853, 610);
      // };
    },
    uploadFileInner(e) {
      if (this.$refs.uploadFileInner.files[0].type.includes("image/")) {
        this.$refs.editor.clear();
        this.$refs.editor.uploadImage(e);
        console.log(this.$refs.canvas)
        this.$refs.canvas.uploadImage(e);
        // var ctx = this.$refs.canvas.getContext("2d");
        // this.canvas = new fabric.Canvas('canvas');
        // this.canvas.setDimensions({ width: 854, height: 480 });
        // console.log(this.canvas);
        // this.canvas.backgroundColor = "#fff";
        // ctx.clearRect(0, 0, 854, 480);
        // var img = new Image();
        // img.src = URL.createObjectURL(e.target.files[0]);
        // // img.src =e.target.result;
        // img.onload = function () {
        //   var image1 = new fabric.Image(img);
        //   // ctx.width = img.width;
        //   // ctx.height = img.height;
        //   // console.log(img.width);
        //   // console.log(ctx.width);
        //   // ctx.drawImage(img, 0, 0, 854, 480);
        //   // ctx.drawImage(img, 0, 0, width, height);
          
        //   // console.log(this.$refs.canvas.height);
        //   if (
        //       854 <= image1.width ||
        //       480 <= image1.height
        //     ) {
        //       var canvasAspect = 854 / 480;
        //       var imgAspect = image1.width / image1.height;
        //       var top, left, scaleFactor;
        //       if (canvasAspect >= imgAspect) {
        //         scaleFactor = 480 / image1.height;
        //         top = 0;
        //         left =
        //           -(image1.width * scaleFactor - 854) / 2;
        //       } else {
        //         scaleFactor = 854 / image1.width;
        //         left = 0;
        //         top =
        //           -(image1.height * scaleFactor - 480) / 2;
        //       }
        //       this.canvas.setBackgroundImage(
        //         image1,
        //         this.canvas.renderAll.bind(this.canvas),
        //         {
        //           top: top,
        //           left: left,
        //         }
        //       );
        //       this.canvas.renderAll();
        //     } else {
        //       var center = this.$refs.canvas.getCenter();
        //       this.$refs.canvas.setBackgroundImage(
        //         image,
        //         this.$refs.canvas.renderAll.bind(this.$refs.canvas),
        //         {
        //           top: center.top,
        //           left: center.left,
        //           originX: "center",
        //           originY: "center",
        //         }
        //       );
        //       this.$refs.canvas.renderAll();
        //     }
        // };
      } else {
        this.$alert("画像の拡張子ではないですので、ご確認ください！");
      }
    },
     saveImage() {
      // const link = document.createElement("a");
      // link.setAttribute("download", "download.png");
      // link.setAttribute(
      //   "href",
      //   this.$refs.editor.canvas
      //     .toDataURL("image/png")
      //     .replace("image/png", "image/octet-stream")
      // );
      // link.click();
      this.showModal2 = true;

    },
    setNameRecord(){
      var url = this.$refs.editor.canvas.toDataURL();
      if (this.namerecord.trim() != "" ) {
        var date = "";
        try {
          date = moment().format("L").replaceAll("/", "");
        } catch (error) {
          var datetime = moment().format("L").split("/");
          for (var i = 0; i < datetime.length; i++) {
            date = date + datetime[i];
          }
        }
        var time1 = moment().format("LTS").split(":");
        var hour = time1[0].length > 1 ? time1[0] : "0" + time1[0];
        var minute = time1[1].length > 1 ? time1[1] : "0" + time1[1];
        var second = time1[2].length > 3 ? time1[2] : "0" + time1[2];
        var time = hour + minute + second.replace(" ", "");
        var name = date + time;
        this.$refs.buttonSetName.style.cssText =
          "pointer-events: none;opacity: 0.6;";
        this.$root.$emit("start-upload-image", name,this.namerecord,url);
        this.$refs.textError.style.cssText = "display: none;";
        this.showModal2= false;
      } else {
        this.$refs.textError.style.cssText = "color:red;display: inline;";
      }
    },

    setColorRed() {
      this.stroke = "#e40000";
      this.setMode();
    },
    setColorYellow() {
      this.stroke = "#e8eb34";
      this.setMode();
    },
    setColorPurple() {
      this.stroke = "#a834eb";
      this.setMode();
    },
    setColorGreen() {
      this.stroke = "#65c31a";
      this.setMode();
    },
    setColorLightblue() {
      this.stroke = "#34b7eb";
      this.setMode();
    },
    setColorPink() {
      this.stroke = "#eb34df";
      this.setMode();
    },
    setColorBlue() {
      this.stroke = "#1a10ad";
      this.setMode();
    },
    setColorBlack() {
      this.stroke = "#000";
      this.setMode();
    },
    urltoFile(url, filename, mimeType) {
    return (fetch(url)
            .then(function (res) {
                return res.arrayBuffer();
            })
            .then(function (buf) {
                return new File([buf], filename, {type: mimeType});
            })
    );
},
    // maxDeviation is the difference that is allowed default: 50kb
// Example: targetFileSizeKb = 500 then result will be between 450kb and 500kb
// increase the deviation to reduce the amount of iterations.
async resizeImage(dataUrl, targetFileSizeKb, maxDeviation = 50) {
    let originalFile = await this.urltoFile(dataUrl, 'test.png', 'image/png');
    if (originalFile.size / 1000 < targetFileSizeKb)
        return dataUrl; // File is already smaller

    let low = 0.0;
    let middle = 0.5;
    let high = 1.0;

    let result = dataUrl;

    let file = originalFile;

    while (Math.abs(file.size / 1000 - targetFileSizeKb) > maxDeviation) {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        const img = document.createElement('img');

        const promise = new Promise((resolve, reject) => {
            img.onload = () => resolve();
            img.onerror = reject;
        });

        img.src = dataUrl;

        await promise;

        canvas.width = Math.round(img.width * middle);
        canvas.height = Math.round(img.height * middle);
        context.scale(canvas.width / img.width, canvas.height / img.height);
        context.drawImage(img, 0, 0);
        file = await this.urltoFile(canvas.toDataURL(), 'test.png', 'image/png');

        if (file.size / 1000 < (targetFileSizeKb - maxDeviation)) {
            low = middle;
        } else if (file.size / 1000 > targetFileSizeKb) {
            high = middle;
        }

        middle = (low + high) / 2;
        result = canvas.toDataURL();
    }

    return result;
},
    setMode() {
      let customizeFreeDrawing;
      if (this.mode == "text") {
        customizeFreeDrawing = {
          fill: this.stroke,
          fontFamily: "Verdana",
          fontSize: 16,
          placeholder: "Type something",
          fontWeight: 100,
          fontStyle: "normal",
        };
      } else if (this.mode == "circle" || this.mode == "rect") {
        customizeFreeDrawing = {  stroke: this.stroke ,strokeWidth: this.slider,strokeUniform: false};
      } else {
        customizeFreeDrawing = {
          stroke: this.stroke,
          strokeWidth: this.slider,
        };
      }
      this.$refs.editor.set(this.mode, customizeFreeDrawing);
    },
    transferFile() {
      var url = this.$refs.editor.canvas.toDataURL();
      this.resizeImage(url,500,50).then((res)=>{
        this.$root.$emit("transfer-File", this.sub, res);
      });;
    },
  },
  beforeDestroy() {
    this.$root.$off('change-camera');
    this.$root.$off('bigcamera-capture');
    this.$root.$off('bigcamera-unload');
    this.$root.$off('close-popup');
    this.$root.$off('upload-file');
  }
};
</script>

<style lang="scss">
@import "./src/assets/scss/abstracts/main";
@import "./src/assets/scss/globals/draws";
label {
  display: contents;
  padding: 0.5rem;
  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: pointer;
  margin-top: 1rem;
}
// #videoStreaming .img {
//     overflow-x: auto; 
//     white-space: nowrap;
// }
// #upload-photo {
//    width: 0.1px;
// 	height: 0.1px;
// 	opacity: 0;
// 	overflow: hidden;
// 	position: absolute;
// 	z-index: -1;
// }
.big-image-wrapper {
  background-color: white;
  //border: 3px solid map-get($color, main);
  padding: 10px 10px;
  border-radius: 10px;
  box-shadow: 0 0px 12px rgba(map-get($color, black), 0.35);
  display: block;
  position: relative;
  border-radius: 0.7rem;
  video,img {
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: .5rem;
    background-color: #000000;
  }
  img {
    max-width: 100%;
    width: 100%;
  }
  @media (max-width: 1199.98px) {
    padding: 10px 10px;
  }
  @media (max-width: 1024.98px) {
    padding: 10px 10px;
  }
  @media (max-width: 767.98px) {
    padding: 10px;
  }
  padding-top: (9 / 16) * 99%!important;
}
.thumbnail-image-wrapper {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #ff6600;
  padding: 10px 25px;
  border-radius: 10px;
  box-shadow: 0 0px 12px rgb(0 0 0 / 35%);
  .row {
    flex-wrap: nowrap;
    overflow: auto;
  }
  .col-2 {
    padding: 5px;
  }
  .thumbnail-item {
    @include img(1);
    cursor: pointer;
    &:hover,
    &-active {
      opacity: 0.6;
    }
    border-top: 2px solid transparent;
    border-radius: 0.8rem;
    border: 4px solid transparent;
  }
  .active{
    border-radius: 0.8rem;
    border: 4px solid red;
  }
}
.vm[aria-label="画像保存"]{
  margin-top: auto;
}
#uploadImage {
  margin-top: auto;
  margin-bottom: auto;
}
.wrapper {
  float: left;
  font-family: "Courier New", Courier, monospace;
}
div.editor {
  margin-top: 10px;
}
div.slider {
  margin-top: 0px;
  margin-left: 10px;
  margin-bottom: 20px;
}
.img {
  @include img(1);
  padding-top: 0%;
  border-radius: 10px;
  // overflow: hidden;
  width: 100%;
  height: 100%;
  //box-shadow: 0 0px 12px rgba(map-get($color, black), 0.35);
}
.fontsize-15 {
  font-size: 15px;
}
//css modal
.vm-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.vm-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}
.vm {
  position: relative;
  margin: 0px auto;
  width: calc(100%);
  min-width: 110px;
  max-width: 950px;
  background-color: #fff;
  top: 30px;
  cursor: default;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  border-radius: 7px;
}
.vm-titlebar {
  text-align: center;
  color: #ffffff;
  padding: 10px 15px 10px 15px;
  overflow: auto;
  border-bottom: 1px solid #e5e5e5;
  background: linear-gradient(#d84c0a, orange);
  border-radius: 7px;
}
.vm-title {
  margin-top: 2px;
  margin-bottom: 0px;
  display: inline-block;
  font-weight: 600;
  font-size: 1.6rem;
}
.vm-btn-close {
  color: #ccc;
  padding: 0px;
  cursor: pointer;
  background: 0 0;
  border: 0;
  float: right;
  font-size: 24px;
  line-height: 1em;
}
.vm-btn-close:before {
  content: "×";
  font-family: Arial;
}
.vm-btn-close:hover,
.vm-btn-close:focus,
.vm-btn-close:focus:hover {
  color: #bbb;
  border-color: transparent;
  background-color: transparent;
}
.vm-content {
  padding: 10px 15px 10px 15px;
  overflow-x: auto;
  overflow-y: hidden;
}
.vm-content .full-hr {
  width: auto;
  border: 0;
  border-top: 1px solid #e5e5e5;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: -14px;
  margin-right: -14px;
}
.vm-fadeIn {
  animation-name: vm-fadeIn;
}
@keyframes vm-fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.vm-fadeOut {
  animation-name: vm-fadeOut;
}
@keyframes vm-fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.vm-fadeIn,
.vm-fadeOut {
  animation-duration: 0.25s;
  animation-fill-mode: both;
}

</style>