<template>
  <video class="img" id="videoStream" autoplay/>
</template>

<script>
export default {
  name: "OvVideo",

  props: {
    streamManager: Object,
  },

  mounted() {
    this.streamManager.addVideoElement(this.$el);
  },
  methods: {
    
  }
};
</script>
<style lang="scss">
@import "./src/assets/scss/abstracts/main";

.img {
  @include img(1);
  padding-top: 0%;
  border-radius: 5px;
  // overflow: hidden;
  width: 100%;
  height: 0;
  margin-bottom: 0.5rem;
  // box-shadow: 0 0px 12px rgba(map-get($color, black), 0.35);
}
</style>