<template>
  <div id="index">
    <div id="demo">
      <transition name="fade">
        <div v-if="!checkConnect" class="offline">現在オフラインです！</div>
      </transition>
    </div>
    <div class="app-header">
      <div class="container-fluid" style="width: 85%">
        <div class="row">
          <div class="app-header-title">
            <p>K-Link</p>
          </div>
          <div class="col">
            <div class="float-right" style="color: white; font-weight: bold">
              名前: {{ user.name }}
            </div>
          </div>

          <!-- <p class="col-10" style="text-align : right;">{{ user.username }}</p> -->
          <div class="app-header-close" v-on:click="logout">
            <p style="margin: auto">ログアウト</p>
            <span class="fal fa-times"></span>
          </div>
          <!-- <div class="app-header-close btn" v-on:click="action1">
            <p style="margin: auto">Upload</p>
            <span class="fal fa-times"></span>
          </div>
          <div class="app-header-close btn" v-on:click="action2">
            <p style="margin: auto">Delete</p>
            <span class="fal fa-times"></span>
          </div>
          <div class="app-header-close btn" v-on:click="action3">
            <p style="margin: auto">Link</p>
            <span class="fal fa-times"></span>
          </div> -->
        </div>
      </div>
    </div>
    <div class="app-body section-pd setbackgroundwhite">
      <div class="container-fluid" style="width: 85%">
        <div class="row">
          <div class="col-xl-9 col-lg-8">
            <BigImage :subscribers="subscribers" />
          </div>
          <div class="col-xl-3 col-lg-4 app-menu">
            <div class="row">
              <div class="col-sm-6 col-md-4 col-lg-12 block-wrapper">
                <CameraImage :publisher="publisher" />
              </div>
              <div class="col-sm-6 col-md-8 col-lg-12 block-wrapper">
                <CameraAction :subscribers="subscribers" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BigImage from "../components/index/BigImage";
import CameraImage from "../components/index/CameraImage";
import CameraAction from "../components/index/CameraAction";
import axios from "axios";
import { OpenVidu } from "openvidu-browser";
// import { resolve } from 'dns';

axios.defaults.headers.post["Content-Type"] = "application/json";

export default {
  name: "IndexPage",

  components: {
    BigImage,
    CameraImage,
    CameraAction,
  },

  data() {
    return {
      OV: undefined,
      session: undefined,
      publisher: undefined,
      subscribers: [],
      firstSubscriber: undefined,
      mySessionId: process.env.VUE_APP_MYSESSION,
      myUserName: process.env.VUE_APP_MYSESSION,
      OPENVIDU_SERVER_SECRET: process.env.VUE_APP_OPENVIDU_SERVER_SECRET,
      OPENVIDU_SERVER_URL: process.env.VUE_APP_OPENVIDU_SERVER_URL,
      canvas: {},
      captures: [],
      flagSession: 0,
      timer: "",
      flagCon: false,
      countDown: 12,
      onLine: null,
      onlineSlot: "online",
      offlineSlot: "offline",
      checkConnect: true,
      recordId: "",
      recordObject: [],
      timeJoin: 0,
      user: [],
      namedump: "",
      namedumpImage: "",
      recordOperator: [],
      flgmenu:0,
      flagCheckSession: false,
      countCheckSession : 0,
      countSession: 0,
      countSessionDisconnect: 0,
      triggerLogin: false,
      subscriber: undefined,
      statusRecord: "",
      flgRecordStop: false,
    };
  },
  created() {
    // check realtime connection network timer 1 second
    this.fetchEventsConnectNetwork();
    this.timer = setInterval(this.fetchEventsConnectNetwork, 1000);
  },
  async mounted() {
    $("body").css({ background: "#eee" });
    $("body").css({ "font-size": "15px" });
    $("body").css({ overflow: "auto" });
    $("body").css({ color: "black" });
    // var s =
    //   "https://videocallserver.kns-cloud.com/openvidu/recordings/Operator1-4/123456879_04012021_053832PM.mp4";
    // console.log(s);
    // var url = s.replace("https://videocallserver.kns-cloud.com", "");
    // console.log(url);
    if (!this.$cookies.isKey("token")) {
      this.$router.push("/login");
    } else {
      var token = this.$cookies.get("token");
      this.checkAuthen(token);
      // .then((data) => {
      //   if (data != null) {
      //     this.user = data;
      //     if (data.roleId == 1) {
      //       this.$router.push("/administrator");
      //     } else {
      //       if (!this.user.active) {
      //         this.logoutSesion();
      //         this.$router.push({
      //           path: "/login",
      //           query: { message: "ユーザーＩＤがアクティブではありません" },
      //         });
      //           // this.$cookies.remove("token");
      //           // this.$cookies.remove("usertoken");
      //       } else {
      //         this.user = data;
      //         this.mySessionId = this.user.operator;
      //         this.myUserName = this.user.operator;
      //         this.joinSession();
      //       }
      //     }
      //   } else {
      //     this.logoutSesion();
      //     this.$router.push("/login");
      //     // this.$cookies.remove("token");
      //     // this.$cookies.remove("usertoken");
      //   }
      // });
    }
    this.$root.$on("change-camera", (sub) => {
      this.firstSubscriber = sub;
    });
    this.$root.$on("transfer-File", (sub, url) => {
      this.postSignal(this.mySessionId, sub, url);
    });
    this.$root.$on("switch-camera", (sub) => {
      this.postSignalSwitchCam(this.mySessionId, sub);
    });
    await this.$root.$on(
      "start-record-video",
      (namerecord, nameStream, name) => {
        this.getStatusRecordAll(this.mySessionId).then(res => {
          this.namedump = name;
          setTimeout(() => {
            this.postPlayRecord(
              this.user.operator,
              namerecord,
              nameStream
            );
          }, 2000);
          
        });
        // if (this.statusRecord == "started" || this.statusRecord == "starting") {
        //   this.postStopRecord(this.recordId);
        // }
        
        // this.postSignalSwitchCam(this.mySessionId,sub);
      }
    );
    await this.$root.$on("start-upload-image",
      (name, namerecord,url) => {
        this.namedumpImage = name;
        this.postUploadImage(
          this.user.operator,
          namerecord,
          url,
          name
        ).then((record) => {
          this.recordId = record;
        });
      }
    );
    await this.$root.$on("stop-record-video", () => {
      
      this.getStatusRecord(this.recordId).then((status) => {
        if (status == "started" || status == "starting") {
          this.postStopRecord(this.recordId);
        }
      });
    });
    await this.$root.$on("start-record-speech", (namerecord, nameStream,name) => {
      this.$root.$emit("stop-record-video");
      this.getStatusRecordAll(this.mySessionId).then(res => {
          this.namedump = name;
          this.postPlayRecordSpeech(
            this.user.operator,
            namerecord,
            nameStream
          ).then((record) => {
            this.recordId = record;
          });
          
        });
      
    });
    await this.$root.$on("stop-record-speech", () => {
      this.getStatusRecord(this.recordId).then((status) => {
        if (status == "started" || status == "starting") {
          this.postStopRecordSpeech(this.recordId);
        }
      });
    });
    this.$root.$on("open_detail", (row) => {
      this.actionGetLink(row.recordurl).then((status) => {
        
      });
    });
    this.$root.$on("listRecord", () => {
      var operator = this.user.operator
      this.getRecordByOperator(
        this.$cookies.get("token"),
        operator,
      ).then((data1) => {
        // this.recordOperator = record;
        this.getRecord().then((data) => {
        // this.recordObject = record;
        this.$root.$emit(
          "recordObjectList",
          data,
          data1,
          operator
        );
      });
      });
      
    });
    await this.$root.$on("removeRecord", (row) => {
      //this.deleteRecord(row.recordid).then((record) => {
        this.deleteRecord(row.recordid);
        this.actionDelete(row.recordurl);
        this.recordDelete(this.$cookies.get("token"),row.datetime);
        this.$root.$emit("successdeleteRecord", row);
        
      //});
    });
  },
  methods: {
    logout() {
      this.checkSession(this.mySessionId);
      if(this.countSession == 1){
      this.deleteSession(this.mySessionId).then((data) => {
        console.log(data);
      });
      }
      this.logoutSesion();
      // this.$cookies.remove("token");
      // this.$cookies.remove("usertoken");
      this.$router.push("/login");

    },
    fetchEventsConnectNetwork() {
      let vm = this;
      if(this.$cookies.isKey("token")){
      this.getConnectServer(this.mySessionId);
      if (this.checkConnect) {
        if (this.flagCon && this.countDown == 0) {
          clearInterval(this.timer);
          this.getStatusRecordDisconnect(this.mySessionId).then((res) => {
            var i = 0;
            for (var s in res.items) {
                // console.log(`${s}: ${response.data.items[s].sessionId}`);
                if(res.items[s].sessionId === vm.mySessionId){
                  if(res.items[s].status === 'started' || res.items[s].status === 'starting'){
                    vm.postStopRecordDisconnect(res.items[s].id).then((data) => {
                      vm.flgRecordStop = true;
                      var objectdata = {
                        timestamp: data.createdAt,
                        operator: data.sessionId,
                        url:
                          "/opt" + data.url.replace(vm.OPENVIDU_SERVER_URL, ""),
                        hasvideo: data.hasVideo,
                        namerecord: vm.namedump,
                        hasimage : false,
                      };
                      vm.createRecordDisconnect(vm.$cookies.get("token"), objectdata).then((data1) => {
                        vm.actionUploadDisconnect("/opt" + data.url.replace(vm.OPENVIDU_SERVER_URL, "")).then((res) => {
                          vm.checkSessionDisconnect(vm.mySessionId);
                          if(vm.countSessionDisconnect == 1){
                            vm.deleteSession(vm.mySessionId).then((data) => {
                              console.log(data);
                            });
                          }
                          setTimeout(() => {
                            vm.$router.push("/login");
                            vm.logoutSesion();
                          }, 5000);
                        });
                      })
                    });
                    i = 1;
                  }
                }
              }
              vm.checkSessionDisconnect(vm.mySessionId);
              if(vm.countSessionDisconnect == 1){
                vm.deleteSession(vm.mySessionId).then((data) => {
                  console.log(data);
                });
              }
              setTimeout(() => {
                vm.$router.push("/login");
                vm.logoutSesion();
              }, 5000); 
              
        });
          // await this.deleteSession(this.mySessionId).then((data) => {});
          // this.$router.go(0);
        } else {
          vm.flagCon = false;
          vm.countDown = 12;
        }
      } else {
        vm.flagCon = true;
        if (vm.countDown > 1) {
          setTimeout(() => {
            vm.countDown -= 1;
          }, 1000);
        }
      }
      }else{
        vm.checkSessionDisconnect(vm.mySessionId);
        if(vm.countSessionDisconnect == 1){
          vm.deleteSession(vm.mySessionId).then((data) => {
            console.log(data);
          });
        }
        setTimeout(() => {
          vm.$router.push("/login");
          vm.logoutSesion();
        }, 2000); 
      }
    },
    close() {
      window.open("", "_self").close();
    },
    async joinSession() {
      // --- Get an OpenVidu object ---
      this.OV = new OpenVidu();
      // Check exits sesseion
      // if (performance.navigation.type !== 1) {
        await this.getSession(this.mySessionId);
      // }
      // if (this.flagSession === 0) {
        // --- Init a session ---
        this.session = this.OV.initSession();

        // --- Specify the actions when events take place in the session ---

        // On every new Stream received...
        this.session.on("streamCreated", ({ stream }) => {
          const subscriber = this.session.subscribe(stream);
          this.subscriber = subscriber;
          this.subscribers.push(subscriber);
          if (this.subscribers.length === 1) {
            this.firstSubscriber = subscriber;
            this.$root.$emit("change-camera", subscriber);
          }
        });
        // On every Stream destroyed...
        this.session.on("streamDestroyed", ({ stream }) => {
          console.log(stream)
          const index = this.subscribers.indexOf(stream.streamManager, 0);
          if (index >= 0) {
            this.subscribers.splice(index, 1);
          }
          
          if(JSON.parse(stream.connection.data).data === true){
            this.getStatusRecordDisconnect(this.mySessionId).then((res) => {
              for (var s in res.items) {
                if(res.items[s].sessionId === this.mySessionId){
                  if(res.items[s].status === 'started' || res.items[s].status === 'starting'){
                    this.postStopRecordDisconnect(res.items[s].id).then((data) => {
                      this.deleteRecord(data.id).then(data1 => console.log("Done"));
                    });
                  }
                }
              }
            });
          }
          // console.log(JSON.parse(stream.connection.data).data)
          this.$root.$emit("device-left", stream.connection.connectionId);
        });

        this.session.on("sessionDisconnected", (event) => {
          if (event.reason === "networkDisconnect") {
            console.log("Dang-it... You lost your connection to the session");
          } else if(event.reason =="sessionClosedByServer") {
            console.log(event);
            this.logoutSesion();
            this.$alert("セッションがタイムアウトしました。再度ログインしてください。")
            this.$router.push("/login");
          }else{
            console.log("Other reasons!");
            console.log(event);
            this.$router.push("/login");
            // Disconnected from the session for other reason than a network drop
          }
        });
        this.session.on("reconnecting", () =>
          console.log("Oops! Trying to reconnect to the session")
        );

        // --- Connect to the session with a valid user token ---

        // 'getToken' method is simulating what your server-side should do.
        // 'token' parameter should be retrieved and returned by your own backend
        this.getToken(this.mySessionId).then((token) => {
          this.session
            .connect(token, { clientData: this.myUserName, user : this.user.username, data: this.user.checkMasterRoom })
            .then(() => {
              // --- Get your own camera stream with the desired properties ---

              let publisher = this.OV.initPublisher(undefined, {
                audioSource: undefined, // The source of audio. If undefined default microphone
                videoSource: undefined, // The source of video. If undefined default webcam
                publishAudio: true, // Whether you want to start publishing with your audio unmuted or not
                publishVideo: true, // Whether you want to start publishing with your video enabled or not
                resolution: "640x480", // The resolution of your video
                frameRate: 30, // The frame rate of your video
                insertMode: "REPLACE", // How the video is inserted in the target element 'video-container'
                mirror: false, // Whether to mirror your local video or not
              });

              this.publisher = publisher;

              // --- Publish your stream ---

              this.session.publish(this.publisher);
            })
            .catch((error) => {
              console.log(
                "There was an error connecting to the session:",
                error.code,
                error.message
              );
            });
        });
        window.addEventListener("beforeunload", this.leaveSession);
      // } else {
      //   // this.$cookies.remove("token");
      //   // this.$cookies.remove("usertoken");
      //   this.flgmenu = 1;
      //   this.$router.push({ path: "/404"});
      // }
    },
    handleConnectivityChange(status) {
      this.onLine = status;
    },

    async leaveSession() {
      setTimeout(
        () =>
          this.getStatusRecord(this.recordId).then(
        (status) => {

          if(status == 'started' || status == 'starting'){
            this.postStopRecord(this.recordId);
          }
        }
      ),
        100
      );
      await this.checkSession(this.mySessionId);
      // if(this.subscriber)
      // --- Leave the session by calling 'disconnect' method over the Session object ---
      
      if (this.session) { 
        console.log(this.session);
        this.publisher.session.disconnect();
      }
      // Empty all properties...
      
      
      // this.session = undefined;
      // this.publisher = undefined;
      // this.subscribers.forEach((sub) => {
      //   sub.stream.session.disconnect();
      // });
      // this.subscribers = [];
      // this.OV = undefined;
      this.subscribers = [];
      delete this.publisher;
      
      // if(this.countSession == 1){
        delete this.session;
        // }
      delete this.OV;
      if(this.countSession == 1){
      setTimeout(
        () =>
          this.deleteSession(this.mySessionId).then((data) => {
            console.log(data);
          }),
        100
      );
      }
        
      
      // window.removeEventListener("beforeunload", this.leaveSession);
    },

    /**
     * --------------------------
     * SERVER-SIDE RESPONSIBILITY
     * --------------------------
     * These methods retrieve the mandatory user token from OpenVidu Server.
     * This behavior MUST BE IN YOUR SERVER-SIDE IN PRODUCTION (by using
     * the API REST, openvidu-java-client or openvidu-node-client):
     *   1) Initialize a Session in OpenVidu Server	(POST /openvidu/api/sessions)
     *   2) Create a Connection in OpenVidu Server (POST /openvidu/api/sessions/<SESSION_ID>/connection)
     *   3) The Connection.token must be consumed in Session.connect() method
     */

    getToken(mySessionId) {
      return this.createSession(mySessionId).then((sessionId) =>
        this.createToken(sessionId)
      );
    },
    checkAuthen(tokenclient) {
        axios
          .post(`${this.OPENVIDU_SERVER_URL}/vti/authentication`, {
            token: tokenclient,
          })
          .then((response) => {
            if (response.status === 200) {
              if (response.data != null) {
                var data1 = response.data;
                if (data1 != null) {
                    if (data1.length != 0) {
                      if (!data1.active) {
                        this.$router.push({
                          path: "/login",
                          query: { message: "ユーザーＩＤがアクティブではありません" },
                        });
                        this.logoutSesion();
                        // this.$cookies.remove("token");
                        // this.$cookies.remove("usertoken");
                      } else {
                        this.data = data1;
                        this.$cookies.set("usertoken", data1, 60 * 60 * 24);
                        if (data1.roleId == 1) {
                          this.$router.push("/administrator");
                        } else {
                          this.mySessionId = data1.operator;
                          this.myUserName = data1.operator;
                          this.checkLoginSession(data1);      
                        }
                      }
                    }
                    // if (data1.roleId == 1) {
                    //   this.$router.push("/dashboard");
                    // } else {
                    //   this.$router.push("/");
                    // }
                  } else {
                    this.$router.push("/login");
                    this.logoutSesion();
                    // this.$cookies.remove("token");
                    // this.$cookies.remove("usertoken");
                  }
              }
            }
          })
          .catch((error) => {
            console.log(error)
            // this.$router.go(0);
          });
    },
    checkLoginSession(data1) {
        axios
          .get(`${this.OPENVIDU_SERVER_URL}/openvidu/api/sessions/${data1.operator}`, {
            auth: {
                username: "OPENVIDUAPP",
                password: this.OPENVIDU_SERVER_SECRET,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              if (response.data != null) {
                this.flgExist = true;
                if(JSON.parse(response.data.connections.numberOfElements) == 10){
                  this.flgCount = true;
                }
                var connection = response.data.connections;
                for (var i = 0; i < connection.content.length; i++) {
                  var content = connection.content[i];
                  // console.log(content.platform.includes('Android'));
                  console.log(JSON.parse(content.clientData).user)
                  if(JSON.parse(content.clientData).user == data1.username){
                    this.flgCheckAuthentication = true;
                    console.log(JSON.parse(content.clientData).user)
                    break;
                  }
                  this.flgCheckAuthentication = false;
                }
                if(this.flgCheckAuthentication){
                  this.$router.push({
                    path: "/login",
                    query: { message: "このアカウントは別の場所にログインしています" },
                  });
                  this.logoutSesion();
                }else{
                  if(this.flgCount == true){
                    this.$router.push({
                      path: "/login",
                      query: { message: "現在、部屋には10人がいます。 部屋に入ることはできません。" },
                    });
                    this.logoutSesion();
                  }else{
                    if(this.flgExist == true){
                      if(!triggerLogin){
                        this.$confirm("部屋はすでに存在します。 部屋に入りますか？").then(() => {
                          this.user = data1;
                          this.mySessionId = this.user.operator;
                          this.myUserName = this.user.operator;
                          this.joinSession();
                        }).catch((error) => {
                          this.$router.push({
                            path: "/login",
                            query: { message: "このアカウントは別の場所にログインしています" },
                          });
                          this.logoutSesion();
                        });
                      }else{
                        this.user = data1;
                        this.mySessionId = this.user.operator;
                        this.myUserName = this.user.operator;
                        this.joinSession();
                      }
                      
                      // this.logoutSesion();
                    }else{
                      this.user = data1;
                      this.mySessionId = this.user.operator;
                      this.myUserName = this.user.operator;
                      this.joinSession();
                    }
                  }
                } 
              }
            }
          })
          .catch((error) => {
            this.flgExist = false;
            console.log(error)
            this.user = data1;
            this.mySessionId = this.user.operator;
            this.myUserName = this.user.operator;
            this.joinSession();
            // this.$router.go(0);
          });
    },
    createRecord(tokenclient, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.OPENVIDU_SERVER_URL}/vti/createRecord`,
            JSON.stringify({
              data,
              token: tokenclient,
            }),
            {}
          )
          .then((response) => {
            
            if (response.status === 200) {
              if (response.data != "fail") {
                // this.$alert("success");
                resolve(response.data)
              } else {
                reject(error.response);
              }
            }
          })
          .catch((error) => {
            
            reject(error.response);
            // this.$alert("fail");
          });
      });
    },
    createRecordDisconnect(tokenclient, data) {
      return new Promise((resolve) => {
        axios
          .post(
            `${this.OPENVIDU_SERVER_URL}/vti/createRecord`,
            JSON.stringify({
              data,
              token: tokenclient,
            }),
            {}
          )
          .then((response) => response.data)
          .then((data) => resolve(data))
          .catch((error) => {
            reject(error.response);
            // this.$alert("fail");
          });
      });
    },
    action1() {
      this.actionUpload("/opt/openvidu/recordings/Operator1-4/04052021020155PM.mp4");
    },
    actionUpload(file_path) {
      axios
        .post(`${this.OPENVIDU_SERVER_URL}/vti/actionuploads3`, {
          file_path,
        })
        .then((response) => {
          if (response.status === 200) {
            console.log(response);
          }
        })

        .catch((error) => console.log(error));
    },
    actionUploadDisconnect(file_path) {
      return new Promise((resolve) => {
        axios
          .post(`${this.OPENVIDU_SERVER_URL}/vti/actionuploads3`, {
            file_path,
          })
          .then((response) => {
            resolve();
          })
          .catch((error) => console.log(error))
          .finally(resolve());
      });
    },
    actionUploadImage(file_path,url) {
      axios
        .post(`${this.OPENVIDU_SERVER_URL}/vti/actionuploadimage`, {
          file_path,
          url
        })
        .then((response) => {
          if (response.status === 200) {
            console.log(response);
          }
        })

        .catch((error) => console.log(error));
    },
    action2() {
      this.actionDelete("/home/administrator/Desktop/a.mp4");
    },
    actionDelete(key_name) {
      axios
        .post(`${this.OPENVIDU_SERVER_URL}/vti/actiondeletes3`, {
          key_name,
        })
        .then((response) => {
          if (response.status === 200) {
            if (response.data != "fail") {
              console.log(response.data);
            }
          }
        })

        .catch((error) => console.log(error));
    },
    recordDelete(tokenuser,timestamp) {
      axios
        .post(`${this.OPENVIDU_SERVER_URL}/vti/deleteRecord`, {
          token : tokenuser,
          time : timestamp,
        })
        .then((response) => {
          if (response.status === 200) {
            if (response.data != "fail") {
              console.log(response.data);
            }
          }
        })

        .catch((error) => console.log(error));
    },
    // See https://docs.openvidu.io/en/stable/reference-docs/REST-API/#post-openviduapisessions
    createSession(sessionId) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.OPENVIDU_SERVER_URL}/openvidu/api/sessions`,
            JSON.stringify({
              customSessionId: sessionId,
              recordingMode: "MANUAL",
              defaultOutputMode: "COMPOSED",
            }),
            {
              auth: {
                username: "OPENVIDUAPP",
                password: this.OPENVIDU_SERVER_SECRET,
              },
            }
          )
          .then((response) => response.data)
          .then((data) => resolve(data.id))
          .catch((error) => {
            if (error.response.status === 409) {
              resolve(sessionId);
            } else {
              console.warn(
                `No connection to OpenVidu Server. This may be a certificate error at ${this.OPENVIDU_SERVER_URL}`
              );
              if (
                window.confirm(
                  `No connection to OpenVidu Server. This may be a certificate error at ${this.OPENVIDU_SERVER_URL}\n\nClick OK to navigate and accept it. If no certificate warning is shown, then check that your OpenVidu Server is up and running at "${this.OPENVIDU_SERVER_URL}"`
                )
              ) {
                location.assign(
                  `${this.OPENVIDU_SERVER_URL}/accept-certificate`
                );
              }
              reject(error.response);
            }
          });
      });
    },

    deleteSession(sessionId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            `${this.OPENVIDU_SERVER_URL}/openvidu/api/sessions/${sessionId}`,
            {
              auth: {
                username: "OPENVIDUAPP",
                password: this.OPENVIDU_SERVER_SECRET,
              },
            }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response);
            this.$router.go(0);
          });
      });
    },
    deleteRecord(sessionId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            `${this.OPENVIDU_SERVER_URL}/openvidu/api/recordings/${sessionId}`,
            {
              auth: {
                username: "OPENVIDUAPP",
                password: this.OPENVIDU_SERVER_SECRET,
              },
            }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response);
            this.$router.go(0);
          });
      });
    },

    getSession(sessionId) {
      // return new Promise((resolve, reject) => {
      return axios
        .get(`${this.OPENVIDU_SERVER_URL}/openvidu/api/sessions/${sessionId}`, {
          auth: {
            username: "OPENVIDUAPP",
            password: this.OPENVIDU_SERVER_SECRET,
          },
        })
        .then((response) => {
          this.flagSession = 1;
          // console.log(this.flagSession);
          // this.$router.push("/404");
        })
        .catch((error) => {
          console.log(error);
          this.flagSession = 0;
          // console.log(this.flagSession);
        });
      // });
    },
    checkSession(sessionId) {
      // return new Promise((resolve, reject) => {
      return axios
        .get(`${this.OPENVIDU_SERVER_URL}/openvidu/api/sessions/${sessionId}`, {
          auth: {
            username: "OPENVIDUAPP",
            password: this.OPENVIDU_SERVER_SECRET,
          },
        })
        .then((response) => {
          console.log(JSON.parse(response.data.connections.numberOfElements))
          console.log(response.data.connections)
          if(JSON.parse(response.data.connections.numberOfElements) == 1){
            this.countSession = 1;
          }else if(JSON.parse(response.data.connections.numberOfElements) == 2){
            var connection = response.data.connections;
            var flg = false;
            for (var i = 0; i < connection.content.length; i++) {
                var content = connection.content[i];
                // console.log(content.platform.includes('Android'));
                if(content.platform.includes('Android')){
                  flg = true;
                  break;
                }
            }
            if(flg == true){
              this.countSession = 1;
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.flagSession = 0;
          // console.log(this.flagSession);
        });
      // });
    },
    checkSessionDisconnect(sessionId) {
      // return new Promise((resolve, reject) => {
      return axios
        .get(`${this.OPENVIDU_SERVER_URL}/openvidu/api/sessions/${sessionId}`, {
          auth: {
            username: "OPENVIDUAPP",
            password: this.OPENVIDU_SERVER_SECRET,
          },
        })
        .then((response) => {
          console.log(JSON.parse(response.data.connections.numberOfElements))
          console.log(response.data.connections)
          var flg = false;
          if(JSON.parse(response.data.connections.numberOfElements) == 1){
            if(response.data.connections.content[0].platform.includes('Android')){
              flg = true;
            }
          }else if(JSON.parse(response.data.connections.numberOfElements) == 2){
            var connection = response.data.connections;
            
            for (var i = 0; i < connection.content.length; i++) {
                var content = connection.content[i];
                // console.log(content.platform.includes('Android'));
                if(content.platform.includes('Android')){
                  flg = true;
                  break;
                }
            }
          }
          if(flg == true){
              this.countSessionDisconnect = 1;
            }
        })
        .catch((error) => {
          console.log(error);
          this.flagSession = 0;
          // console.log(this.flagSession);
        });
      // });
    },
    postSignal(sessionId, sub, url) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.OPENVIDU_SERVER_URL}/openvidu/api/signal`,
            JSON.stringify({
              session: sessionId,
              data: url,
              to: [sub.stream.connection.connectionId], // Array of Connection objects (optional. Broadcast to everyone if empty)
              type: "my-chat", // The type of message (optional)
            }),
            {
              auth: {
                username: "OPENVIDUAPP",
                password: this.OPENVIDU_SERVER_SECRET,
              },
            }
          )
          .then((response) => {
            console.log("Message successfully sent");
            this.$alert("画像を送りました！");
          })
          .catch((error) => {
            console.log(error);
            this.$alert("画像の送信が失敗でした！");
          });
      });
    },
    postSignalSwitchCam(sessionId, sub) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.OPENVIDU_SERVER_URL}/openvidu/api/signal`,
            JSON.stringify({
              session: sessionId,
              data: sub,
              // data: sub.stream.connection.connectionId,
              // to: [sub.stream.connection.connectionId], // Array of Connection objects (optional. Broadcast to everyone if empty)
              type: "switch-camera", // The type of message (optional)
            }),
            {
              auth: {
                username: "OPENVIDUAPP",
                password: this.OPENVIDU_SERVER_SECRET,
              },
            }
          )
          .then((response) => {
            console.log("Message successfully sent");
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    postPlayRecord(selectedStreamId, namerecord, nameStream) {
    return axios
          .post(
            `${this.OPENVIDU_SERVER_URL}/openvidu/api/recordings/start`,
            JSON.stringify({
              session: selectedStreamId,
              name: namerecord,
              // outputMode: "INDIVIDUAL",
              outputMode: "COMPOSED",
              hasAudio: true,
              hasVideo: true,
              recordingLayout: "CUSTOM",
              customLayout: `${this.OPENVIDU_SERVER_URL}/openvidu/layouts/index.html?sessionId=${selectedStreamId}&secret=123456&hostId=${nameStream}&clientId=${JSON.parse(this.subscriber.stream.session.options.metadata).user}`,
              // customLayout: 'https://videocallserver.kns-cloud.com/openvidu/layouts/index.html?sessionId=Operator1&secret=123456&hostId',
              // resolution: "1280x720",
              resolution: "1920x1080",
            }),
            {
              auth: {
                username: "OPENVIDUAPP",
                password: this.OPENVIDU_SERVER_SECRET,
              },
            }
          )
          .then((response) => response.data)
          .then((data) => {
            this.$root.$emit("stopRecord");
            this.recordId = data.id;
          })
          .catch((error) => {
            if (error.response.status === 409) {
              this.restartServer();
            }
            console.log(error);
            
          });
    },
    postStopRecord(sessionId) {
      return axios
        .post(
          `${this.OPENVIDU_SERVER_URL}/openvidu/api/recordings/stop/${sessionId}`,
          JSON.stringify({}),
          {
            auth: {
              username: "OPENVIDUAPP",
              password: this.OPENVIDU_SERVER_SECRET,
            },
          }
        )
        .then((response) => {
          this.$root.$emit("startRecord");
          console.log(response.data);
          this.flgRecordStop = true;
          // var objectdata = {
          //   timestamp: response.data.createdAt,
          //   operator: response.data.sessionId,
          //   url:
          //     "/opt" + response.data.url.replace(this.OPENVIDU_SERVER_URL, ""),
          //   hasvideo: response.data.hasVideo,
          //   namerecord: this.namedump,
          //   hasimage : false,
          // };
          var objectdata = {
            timestamp: response.data.createdAt,
            operator: response.data.sessionId,
            url:
              "/opt" + response.data.url.replace(this.OPENVIDU_SERVER_URL, ""),
            hasvideo: response.data.hasVideo,
            namerecord: this.namedump,
            hasimage : false,
          };
          if(this.$cookies.get("token") != null){
            this.createRecord(this.$cookies.get("token"), objectdata).then((res) => {
              if(res != 'fail'){
                this.actionUpload("/opt" + response.data.url.replace(this.OPENVIDU_SERVER_URL, ""));
                this.$alert("レコードを停止しました。");
              }
            });
            // this.actionUpload("/opt" + response.data.url.replace(this.OPENVIDU_SERVER_URL, ""));
            
          }
        })
        .catch((error) => {
          if (error.response.status === 409) {
              this.restartServer();
          }
          console.log(error);
        });
    },
    postStopRecordDisconnect(sessionId) {
      return new Promise(resolve => {
       axios
        .post(
          `${this.OPENVIDU_SERVER_URL}/openvidu/api/recordings/stop/${sessionId}`,
          JSON.stringify({}),
          {
            auth: {
              username: "OPENVIDUAPP",
              password: this.OPENVIDU_SERVER_SECRET,
            },
          }
        )
        .then((response) => response.data)
        .then((data) => {
            resolve(data);
        })
        .catch((error) => {
          if (error.response.status === 409) {
              this.restartServer();
          }
          console.log(error);
        });
      }); 
    },
    postStopRecord(sessionId) {
      return axios
        .post(
          `${this.OPENVIDU_SERVER_URL}/openvidu/api/recordings/stop/${sessionId}`,
          JSON.stringify({}),
          {
            auth: {
              username: "OPENVIDUAPP",
              password: this.OPENVIDU_SERVER_SECRET,
            },
          }
        )
        .then((response) => {
          this.$root.$emit("startRecord");
          console.log(response.data);
          this.flgRecordStop = true;
          // var objectdata = {
          //   timestamp: response.data.createdAt,
          //   operator: response.data.sessionId,
          //   url:
          //     "/opt" + response.data.url.replace(this.OPENVIDU_SERVER_URL, ""),
          //   hasvideo: response.data.hasVideo,
          //   namerecord: this.namedump,
          //   hasimage : false,
          // };
          var objectdata = {
            timestamp: response.data.createdAt,
            operator: response.data.sessionId,
            url:
              "/opt" + response.data.url.replace(this.OPENVIDU_SERVER_URL, ""),
            hasvideo: response.data.hasVideo,
            namerecord: this.namedump,
            hasimage : false,
          };
          if(this.$cookies.get("token") != null){
            this.createRecord(this.$cookies.get("token"), objectdata).then((res) => {
              if(res != 'fail'){
                this.actionUpload("/opt" + response.data.url.replace(this.OPENVIDU_SERVER_URL, ""));
                this.$alert("レコードを停止しました。");
              }
            });
          }
        })
        .catch((error) => {
          if (error.response.status === 409) {
              this.restartServer();
          }
          console.log(error);
        });
    },
    postStopRecordSession(sessionId) {
      return new Promise(resolve => {
        axios
        .post(
          `${this.OPENVIDU_SERVER_URL}/openvidu/api/recordings/stop/${sessionId}`,
          JSON.stringify({}),
          {
            auth: {
              username: "OPENVIDUAPP",
              password: this.OPENVIDU_SERVER_SECRET,
            },
          }
        )
        .then((response) => {
          console.log("success");
          resolve();
        })
        .catch((error) => {
          if (error.response.status === 409) {
              this.restartServer();
          }
          console.log(error);
        });
      });
    },
    urltoFile(url, filename, mimeType) {
      return (fetch(url)
              .then(function (res) {
                  return res.arrayBuffer();
              })
              .then(function (buf) {
                  return new File([buf], filename, {type: mimeType});
              })
      );
    },
    // maxDeviation is the difference that is allowed default: 50kb
// Example: targetFileSizeKb = 500 then result will be between 450kb and 500kb
// increase the deviation to reduce the amount of iterations.
async resizeImage(dataUrl, targetFileSizeKb, maxDeviation = 50) {
    let originalFile = await this.urltoFile(dataUrl, 'test.png', 'image/png');
    if (originalFile.size / 1000 < targetFileSizeKb)
        return dataUrl; // File is already smaller

    let low = 0.0;
    let middle = 0.5;
    let high = 1.0;

    let result = dataUrl;

    let file = originalFile;

    while (Math.abs(file.size / 1000 - targetFileSizeKb) > maxDeviation) {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        const img = document.createElement('img');

        const promise = new Promise((resolve, reject) => {
            img.onload = () => resolve();
            img.onerror = reject;
        });

        img.src = dataUrl;

        await promise;

        canvas.width = Math.round(img.width * middle);
        canvas.height = Math.round(img.height * middle);
        context.scale(canvas.width / img.width, canvas.height / img.height);
        context.drawImage(img, 0, 0);
        file = await this.urltoFile(canvas.toDataURL(), 'test.png', 'image/png');

        if (file.size / 1000 < (targetFileSizeKb - maxDeviation)) {
            low = middle;
        } else if (file.size / 1000 > targetFileSizeKb) {
            high = middle;
        }

        middle = (low + high) / 2;
        result = canvas.toDataURL();
    }

    return result;
},
    postUploadImage(operatorName , nameRecord,url1,name) {
      var objectdata = {
        timestamp: Date.now(),
        operator: operatorName,
        url:
        //old: "/opt" + "/openvidu/recordings/" + operatorName+ "/" + name + ".png",
           "/opt" + "/openvidu/recordings/" + operatorName+ "/" + name + ".png",
        hasvideo: false,
        namerecord: nameRecord,
        hasimage: true
      };
      if(this.$cookies.get("token") != null){
        this.createRecord(this.$cookies.get("token"), objectdata).then((res) => {
              if(res != 'fail'){
                this.resizeImage(url1,750,50).then((res)=>{
                  this.actionUploadImage("/opt/openvidu/recordings/" + operatorName+ "/" + name + ".png",res);
                  this.$alert("画像が保存されました。");
                });
              }
            });
      }
    },
    postPlayRecordSpeech(selectedStreamId, namerecord, nameStream) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.OPENVIDU_SERVER_URL}/openvidu/api/recordings/start`,
            JSON.stringify({
              session: selectedStreamId,
              name: namerecord,
              outputMode: "COMPOSED",
              hasAudio: true,
              hasVideo: false,
              recordingLayout: "CUSTOM",
              customLayout: `${this.OPENVIDU_SERVER_URL}/openvidu/layouts/index.html?sessionId=${selectedStreamId}&secret=123456&hostId=${nameStream}&clientId=${this.user.username}`,
              resolution: "1920x1080",
            }),
            {
              auth: {
                username: "OPENVIDUAPP",
                password: this.OPENVIDU_SERVER_SECRET,
              },
            }
          )
          .then((response) => response.data)
          .then((data) => {
            this.$root.$emit("stopRecordSpeech");
            resolve(data.id);
          })
          .catch((error) => {
            if (error.response.status === 409) {
              this.restartServer();
            }else{
              console.log(error);
            }
          });
      });
    },
    postStopRecordSpeech(sessionId) {
      return axios
        .post(
          `${this.OPENVIDU_SERVER_URL}/openvidu/api/recordings/stop/${sessionId}`,
          JSON.stringify({}),
          {
            auth: {
              username: "OPENVIDUAPP",
              password: this.OPENVIDU_SERVER_SECRET,
            },
          }
        )
        .then((response) => {
          this.$root.$emit("startRecord");
          // var objectdata = {
          //   timestamp: response.data.createdAt,
          //   operator: response.data.sessionId,
          //   url:
          //     "/opt" + response.data.url.replace(this.OPENVIDU_SERVER_URL, ""),
          //   hasvideo: response.data.hasVideo,
          //   namerecord: this.namedump,
          //   hasimage : false,
          // };
          var objectdata = {
            timestamp: response.data.createdAt,
            operator: response.data.sessionId,
            url:
              "/opt" + response.data.url.replace(this.OPENVIDU_SERVER_URL, ""),
            hasvideo: response.data.hasVideo,
            namerecord: this.namedump,
            hasimage : false,
          };
          if(this.$cookies.get("token") != null){
            this.createRecord(this.$cookies.get("token"), objectdata).then((res) => {
              if(res != 'fail'){
                this.actionUpload("/opt" + response.data.url.replace(this.OPENVIDU_SERVER_URL, ""));
                this.$alert("レコードを停止しました。");
              }
            });
          }
          // this.actionUpload(
          //   "/opt" + response.data.url.replace(this.OPENVIDU_SERVER_URL, "")
          // );
        })
        .catch((error) => {
          if (error.response.status === 409) {
            this.restartServer();
          }
          console.log(error);
        });
    },
    restartServer(){
      var token = this.$cookies.get("token");
      this.postRestartServer(token);
    },
    postRestartServer(tokenclient){
      return axios
        .post(
          `${this.OPENVIDU_SERVER_URL}/vti/generateRestart`,
          JSON.stringify({token: tokenclient,}),
        )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getRecord() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${this.OPENVIDU_SERVER_URL}/openvidu/api/recordings`, {
            auth: {
              username: "OPENVIDUAPP",
              password: this.OPENVIDU_SERVER_SECRET,
            },
          })
          .then((response) => response.data)
          .then((data) => {
            // console.log(data.items);
            resolve(data.items);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    getRecordByOperator(tokenclient, operatorname) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.OPENVIDU_SERVER_URL}/vti/getListRecord`,
            JSON.stringify({
              operator: operatorname,
              token: tokenclient,
            })
          )
          .then((response) => response.data)
          .then((data) => {
            
            resolve(data);
          })
          .catch((error) => {
            reject(error.response);
          });
      });
    },
    getStatusRecord(recordId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${this.OPENVIDU_SERVER_URL}/openvidu/api/recordings/${recordId}`,
            {
              auth: {
                username: "OPENVIDUAPP",
                password: this.OPENVIDU_SERVER_SECRET,
              },
            }
          )
          .then((response) => response.data)
          .then((data) => {
            resolve(data.status);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    getStatusRecordStart(recordId) {
        return axios
        .get(`${this.OPENVIDU_SERVER_URL}/openvidu/api/recordings/${recordId}`, {
          auth: {
            username: "OPENVIDUAPP",
            password: this.OPENVIDU_SERVER_SECRET,
          },
        })
        .then((response) => {
          
            this.statusRecord = response.data.status;
            console.log(response.data.status)
        })
        .catch((error) => {
            console.log(error);
        });
    },
    getStatusRecordAll(sessionId) {
        return new Promise(resolve => {
          axios.get(`${this.OPENVIDU_SERVER_URL}/openvidu/api/recordings`, {
            auth: {
              username: "OPENVIDUAPP",
              password: this.OPENVIDU_SERVER_SECRET,
            },
          })
          .then((response) => {
              // if(response.data.)
              // console.log(response.data.items)
              for (var s in response.data.items) {
                // console.log(`${s}: ${response.data.items[s].sessionId}`);
                if(response.data.items[s].sessionId == sessionId){
                  if(response.data.items[s].status == 'started' || response.data.items[s].status == 'starting'){    
                    this.postStopRecordSession(response.data.items[s].id).then(() => {
                      this.deleteRecord(response.data.items[s].id).then(() =>resolve('DONE'))
                    }
                    );
                  }
                }
              }
              // this.statusRecord = response.data.status;
              
            })
          .catch((error) => {
              console.log(error);
          }).finally(() => {
            resolve('DONE');
          });
        })
    },
    getStatusRecordDisconnect(sessionId) {
        return new Promise(resolve => {
          axios.get(`${this.OPENVIDU_SERVER_URL}/openvidu/api/recordings`, {
            auth: {
              username: "OPENVIDUAPP",
              password: this.OPENVIDU_SERVER_SECRET,
            },
          })
          .then((response) => 
              resolve(response.data)
              // for (var s in response.data.items) {
              //   if(response.data.items[s].sessionId == sessionId){
              //     if(response.data.items[s].status == 'started' || response.data.items[s].status == 'starting'){
              //       this.postStopRecordDisconnect(response.data.items[s].id).then((data) => {
              //         this.flgRecordStop = true;
              //         var objectdata = {
              //           timestamp: data.createdAt,
              //           operator: data.sessionId,
              //           url:
              //             "/home/ec2-user" + data.url.replace(this.OPENVIDU_SERVER_URL, ""),
              //           hasvideo: data.hasVideo,
              //           namerecord: this.namedump,
              //           hasimage : false,
              //         };
              //         this.createRecordDisconnect(this.$cookies.get("token"), objectdata).then(data1 => {
              //             this.actionUploadDisconnect("/home/ec2-user" + data.url.replace(this.OPENVIDU_SERVER_URL, "")).then((res) => {resolve('DONE');});
              //         })
              //       });
              //     }
              //   }
              // }
            )
         
          .catch((error) => {
              console.log(error);
          })
        })
    },
    getStatusRecordS(sessionId) {
        return new Promise(resolve => {
          axios.get(`${this.OPENVIDU_SERVER_URL}/openvidu/api/recordings`, {
            auth: {
              username: "OPENVIDUAPP",
              password: this.OPENVIDU_SERVER_SECRET,
            },
          })
          .then((response) => {
              // if(response.data.)
              // console.log(response.data.items)
              for (var s in response.data.items) {
                // console.log(`${s}: ${response.data.items[s].sessionId}`);
                if(response.data.items[s].sessionId == sessionId){
                  if(response.data.items[s].status == 'started' || response.data.items[s].status == 'starting'){
                    
                    this.postStopRecordSession(response.data.items[s].id).then(() => resolve('DONE'));
                  }
                }
              }
              // this.statusRecord = response.data.status;
              
            })
          .catch((error) => {
              console.log(error);
          }).finally(() => {
            resolve('DONE');
          });
        })
    },
    deleteRecord(recordId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            `${this.OPENVIDU_SERVER_URL}/openvidu/api/recordings/${recordId}`,
            {
              auth: {
                username: "OPENVIDUAPP",
                password: this.OPENVIDU_SERVER_SECRET,
              },
            }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    getConnectServer(sessionId) {
      // return new Promise((resolve, reject) => {
      return axios
        .get(`${this.OPENVIDU_SERVER_URL}/openvidu/api/config`, {
          auth: {
            username: "OPENVIDUAPP",
            password: this.OPENVIDU_SERVER_SECRET,
          },
        })
        .then((response) => {
          this.checkConnect = true;
          this.flagCheckSession = true;
          this.timeJoin = this.timeJoin + 1;
        })
        .catch((error) => {
            this.checkConnect = false;
            this.timeJoin = this.timeJoin + 1;
          if(error.response.status == "404"){
            console.log(this.countCheckSession)
            if(this.countCheckSession != 0 ){
              console.log("session not found")
              this.flagCheckSession = false;
              return;
            }
            this.countCheckSession ++;
          }
        });
      // });
    },

    // See https://docs.openvidu.io/en/stable/reference-docs/REST-API/#post-openviduapisessionsltsession_idgtconnection
    createToken(sessionId) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.OPENVIDU_SERVER_URL}/openvidu/api/sessions/${sessionId}/connection`,
            { role: "MODERATOR" },
            {
              auth: {
                username: "OPENVIDUAPP",
                password: this.OPENVIDU_SERVER_SECRET,
              },
            }
          )
          .then((response) => response.data)
          .then((data) => resolve(data.token))
          .catch((error) => reject(error.response));
      });
    },
    logoutSesion(){
      this.$cookies.remove("token");
      this.$cookies.remove("usertoken");
    },
    delAuthen(tokenid) {
      axios
        .post(`${this.OPENVIDU_SERVER_URL}/vti/delAuthen`, {
          token: tokenid,
        })
        .then((response) => {
          if (response.status === 200) {
            if (response.data == "success") {
              this.flg = true;
            }
          }
        })

        .catch((error) => console.log(error));
    },
  },
  // Did you forget that it should be destroyed?
  beforeDestroy() {
    clearInterval(this.timer);
    if(this.flgmenu == 0){
      this.leaveSession();
    }
    this.$root.$off('change-camera');
    this.$root.$off('transfer-File');
    this.$root.$off('switch-camera');
    this.$root.$off('start-record-video');
    this.$root.$off('start-upload-image');
    this.$root.$off('stop-record-video');
    this.$root.$off('start-record-speech');
    this.$root.$off('stop-record-speech');
    this.$root.$off('open_detail');
    this.$root.$off('listRecord');
    this.$root.$off('removeRecord');
  },
};
</script>
<style lang="scss" scoped>
@import "./src/assets/scss/abstracts/main";
@import "./src/assets/scss/globals/modal";

// SCSS for Index page
body {
  font-size: 15px;
  background-color: #eee !important;
  background: #eee;
}
#index {
  font-size: 15px;
}
.setbackgroundwhite {
  background-color: #eee;
}
.app-header {
  padding: 10px;
  background: map-get($color, gardient);
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.app-header-title {
  p {
    text-transform: uppercase;
    font-size: 2.4rem;
    color: map-get($color, white);
  }
}
.app-header-close {
  padding: 8px;
  background-color: darken(map-get($color, white), 10%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 7px;
  transition: all 0.3s ease;
  p {
    margin-right: 10px;
    transition: all 0.3s ease;
  }
  span {
    font-size: 20px;
    transition: all 0.3s ease;
  }
  @media (min-width: 1025px) {
    &:hover {
      background-color: darken(map-get($color, main), 40%);
      p,
      span {
        color: map-get($color, white);
      }
    }
  }
}
// .app-menu {
//   @media (max-width: 1024.98px) {
//     margin-top: 30px;
//   }
// }
</style>