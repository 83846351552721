<template>
  <div id="camera-image">
    <div class="app-aside">
      <p class="block-title">カメラ画像</p>
      <div class="camera-image-wrapper" >
          <user-video
            :stream-manager="publisher"
          />
          <i
          :class="[isMuted ? 'fa fa-microphone-slash fa-lg':'fa fa-microphone fa-lg']"  @click="mute(publisher)"
          >
          </i>
      </div>
      
    </div>
  </div>
</template>

<script>
import UserVideo from "./UserVideo";

export default {
  name: "CameraImage",
  props: ["publisher"],
  components: {
    UserVideo,
  },
  data() {
    return {
    isMuted: false,
    isBlind: false,
    }
  },
  methods:{
    mute(publisher){
      this.isMuted = !this.isMuted;
      publisher.publishAudio(!this.isMuted);
      console.log(this.isMuted);
    },
    // blind(publisher){
    //   this.blind = !this.blind;
    //   publisher.publishVideo(!this.blind);
    // }
  }
};
</script>
<style lang="scss">
@import "./src/assets/scss/abstracts/main";
@import "./src/assets/scss/globals/draws";
.fa-microphone{
  display: inline;
  cursor: pointer;
  margin-left: 47%;
}
.fa-microphone-slash{
  display: inline;
  cursor: pointer;
  margin-left: 45.5%;
}
// .fa-video{
//   display: inline;
//   cursor: pointer;
//   margin-left: 50%;
// }
// .fa-video-slash{
//   display: inline;
//   cursor: pointer;
//   margin-left: 50%;
// }

</style>

